.wrapper{
    display: inline-block;

    margin: 20px;
    border:3px solid #2662B3;
    font-family: Gilroy;
    text-align: center;
    border-radius: 20px;
    font-weight: 800;
    min-width: 220px;
}

.title{
    font-size: 28px;
    color:#2662B3;
    margin: 12px auto 15px auto;
    text-align: center;
}

.team_name{
    font-size: 28px;
    color:#2662B3;
    margin: 10px;
    text-align: center;
}